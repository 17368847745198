// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-adhd-js": () => import("./../../../src/pages/adhd.js" /* webpackChunkName: "component---src-pages-adhd-js" */),
  "component---src-pages-aphasia-js": () => import("./../../../src/pages/aphasia.js" /* webpackChunkName: "component---src-pages-aphasia-js" */),
  "component---src-pages-aspergers-js": () => import("./../../../src/pages/aspergers.js" /* webpackChunkName: "component---src-pages-aspergers-js" */),
  "component---src-pages-autism-js": () => import("./../../../src/pages/autism.js" /* webpackChunkName: "component---src-pages-autism-js" */),
  "component---src-pages-childpsy-js": () => import("./../../../src/pages/childpsy.js" /* webpackChunkName: "component---src-pages-childpsy-js" */),
  "component---src-pages-cnteam-js": () => import("./../../../src/pages/cnteam.js" /* webpackChunkName: "component---src-pages-cnteam-js" */),
  "component---src-pages-convoicedisorder-js": () => import("./../../../src/pages/convoicedisorder.js" /* webpackChunkName: "component---src-pages-convoicedisorder-js" */),
  "component---src-pages-counselling-js": () => import("./../../../src/pages/counselling.js" /* webpackChunkName: "component---src-pages-counselling-js" */),
  "component---src-pages-devdelays-js": () => import("./../../../src/pages/devdelays.js" /* webpackChunkName: "component---src-pages-devdelays-js" */),
  "component---src-pages-devdyslexia-js": () => import("./../../../src/pages/devdyslexia.js" /* webpackChunkName: "component---src-pages-devdyslexia-js" */),
  "component---src-pages-dyslexia-js": () => import("./../../../src/pages/dyslexia.js" /* webpackChunkName: "component---src-pages-dyslexia-js" */),
  "component---src-pages-jha-js": () => import("./../../../src/pages/jha.js" /* webpackChunkName: "component---src-pages-jha-js" */),
  "component---src-pages-keyevent-js": () => import("./../../../src/pages/keyevent.js" /* webpackChunkName: "component---src-pages-keyevent-js" */),
  "component---src-pages-knowledge-js": () => import("./../../../src/pages/knowledge.js" /* webpackChunkName: "component---src-pages-knowledge-js" */),
  "component---src-pages-mainland-js": () => import("./../../../src/pages/mainland.js" /* webpackChunkName: "component---src-pages-mainland-js" */),
  "component---src-pages-np-js": () => import("./../../../src/pages/np.js" /* webpackChunkName: "component---src-pages-np-js" */),
  "component---src-pages-nsp-js": () => import("./../../../src/pages/nsp.js" /* webpackChunkName: "component---src-pages-nsp-js" */),
  "component---src-pages-plans-js": () => import("./../../../src/pages/plans.js" /* webpackChunkName: "component---src-pages-plans-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-puberphonia-js": () => import("./../../../src/pages/puberphonia.js" /* webpackChunkName: "component---src-pages-puberphonia-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-sensory-js": () => import("./../../../src/pages/sensory.js" /* webpackChunkName: "component---src-pages-sensory-js" */),
  "component---src-pages-speech-js": () => import("./../../../src/pages/speech.js" /* webpackChunkName: "component---src-pages-speech-js" */),
  "component---src-pages-speechtherapy-js": () => import("./../../../src/pages/speechtherapy.js" /* webpackChunkName: "component---src-pages-speechtherapy-js" */),
  "component---src-pages-swallowingdifficulty-js": () => import("./../../../src/pages/swallowingdifficulty.js" /* webpackChunkName: "component---src-pages-swallowingdifficulty-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-therapy-js": () => import("./../../../src/pages/therapy.js" /* webpackChunkName: "component---src-pages-therapy-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-terms-page-js": () => import("./../../../src/templates/terms-page.js" /* webpackChunkName: "component---src-templates-terms-page-js" */)
}

